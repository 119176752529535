import attendanceLabour from "../images/attendanceLabour.svg";
import attendancePaperDriven from "../images/attendancePaperDriven.svg";
import attendanceRecord from "../images/attendanceRecord.svg";
import attendanceClock from "../images/attendanceClock.svg";
import attendanceRostering from "../images/attendanceRostering.svg";
import attendanceFoolproof from "../images/attendanceFoolproof.svg";
import attendanceRealtime from "../images/attendanceRealtime.svg";
import attendanceConfigurable from "../images/attendanceConfigurable.svg";



export const attendanceBannerContent = {
    title: {
        coloured: "Talntworx ",
        normal:"Attendance Management System"
    },
    paraLine: "Talntworx's TalntX software facilitates hiring decisions, automates background verification, attendance management and much more to enrich the workforce management experience.",
    button:"Book a Demo"
}

export const attendanceSectionTwoContent = {
    firstBox: {
        title: {
            normal: "Make workforce ",
            coloured:"management Future-ready"
        },
        paraLine:"Time to bid goodbye to piles of record books and spreadsheets cut the management cost and increase payroll accuracy. Our attendance management system secures the details of employees safely and makes the entire process smooth and easy."
    },
    secondBox: {
        title: {
            normal: "and attendance recording",
            coloured:"Swift Time tracking "
        },
        paraLine:"With Talntworx's services, recording attendance, leave management and keeping precise track of time is an automated task (owing to integrated tracking feature). TalntX is a one-stop-solution for all attendance management tasks."
    }

}

export const attendanceSectionThreeContent = {
    title: {
        normal: "Talntworx ",
        coloured: "automated and online attendance management",
        normalContinuation:"system"
    },
    paraLine: "No matter your company's size, we will take care of the attendance records maintenance and accuracy to enhance your workforce productivity. Payroll accuracy can increase, and manual data entry can decrease with automated time and attendance software.",
    data: [
        {
            icon: attendancePaperDriven,
            text:"Manual, paper backed records are error-prone"
        },
        {
            icon: attendanceRecord,
            text:"Inconsistency in records maintenance"
        },
        {
            icon: attendanceClock,
            text:"Delay in access to information"
        },
        {
            icon: attendanceLabour,
            text:"Non compliance with different labor laws of State"
        },
    ]
}

export const attendanceSectionFourContent = [
    {
            title: "Configurable",
            listData: [
                "Customizable as per the company's needs",
                "Force signout options are available",
                "Easy operation"
            ],
            image:attendanceConfigurable
        },
        {
            title: "Foolproof attendance and payroll integration",
            listData: [
                "Offline mode for attendance recording",
                "Biometric enabled",
                "Group attendance marking is feasible"
            ],
            image:attendanceFoolproof
        },
        {
            title: "Simpler rostering, reports, regularisation",
            listData: [
                "Intuitive user interface for faster staff scheduling",
                "Easily import the previous roster",
                "Configure shifts, weekly offs and leaves as per requirements"
            ],
            image:attendanceRostering
    },
        {
            title: "Real-time data insights and reports for all sites",
            listData: [
                "Easy access to the attendance report",
                "Automated email alerts",
                "Provides real-time insights"
            ],
            image:attendanceRealtime
        }
]