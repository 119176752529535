import React from 'react';
import { attendanceBannerContent } from '../../data/attendanceContent';
import { useModal } from '../ContactForm';
import "./attendance.css";

export const AttendanceBanner = () => {
  const {modalAction}=useModal()
  return (
      <section className='lg:h-[550px] 2xl:h-[680px] attendanceBanner flex flex-col items-center justify-center space-y-6  text-white p-4 pb-8'>
          <h1 className='text-[35px] lg:text-[40px] xl:text-[48px] font-[700]'><span className='text-[#0361FE]'>{attendanceBannerContent.title.coloured}</span>{attendanceBannerContent.title.normal}</h1>
          <p className='lg:w-[720px] text-[18px] lg:text-center leading-[40px]'>{attendanceBannerContent.paraLine}</p>
          <button className='bg-[#0361FE] inline text-white py-[11px] px-[25px] rounded-[48px]' onClick={modalAction}>{attendanceBannerContent.button}</button>
    </section>
  )
}
